@charset "UTF-8";

/// Checks if a list contains a value(s).
///
/// @argument {list} $list
///   The list to check against.
///
/// @argument {list} $values
///   A single value or list of values to check for.
///
/// @return {boolean}
///
/// @access private

@function _contains(
    $list,
    $values...
  ) {

  @each $value in $values {
    @if type-of(index($list, $value)) != "number" {
      @return false;
    }
  }

  @return true;
}
