@charset "utf-8";

// Debugging
$debugging: false;
// @import "debugging";

// Grid & Breakpoint Configuration
@import "neat/neat-helpers";

// Should match whatever is in _grid-lover.scss
$default-line-height: 24px;

// Matches the width that Apple uses, in conjunction with a viewport of 1024.
$max-width: 980px;

// Imports
@import "bourbon/bourbon";
@import "neat/neat";
@import "bitters/base";
@import "grid-lover";

// Site specific styles
body {
  color: #161616;
  margin: 0;
}

// Links
a {
  color: #ae0303;
  
  &.embedded {
    color: #161616;
    border-bottom: 1px dotted #161616;
  }
}

.centered {
  text-align: center;
}

//
// Base Section
//
section {
  @include outer-container; 
  width: 980px;
}

h1 {
  border-bottom: 1px solid #363636;
  padding-bottom: $default-line-height;
}

//
// Bio Section
// 
#bio {
  .container { 
    @include span-columns(8);
    @include shift(2);

    border-bottom: 1px solid #767676;
    margin-bottom: $default-line-height;
  }

  h1 {
    text-align: center;
  }
}

//
// Site Footer
//
#site-footer {
  @include outer-container; 
  font-size: 13px;
  width: 980px;

  .container { 
    @include span-columns(12);

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $default-line-height / 2 0;

    &>:last-child { 
      flex-grow: 2; 
      text-align: right;
    }
  }

  p { margin: 0; }
}