/*
 *  http://www.gridlover.net/try
 *
 *  Based on the following settings:
 *
 */

 body {
   font-family: -apple-system, Helvetica, Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
   font-size: 16px;
   line-height: 24px;
   // max-width: 760px;
   // padding: 24px;
   // margin: 0 auto;
 }
 h1 {
   font-family: inherit;
   font-weight: 200;
   font-style: inherit;
   font-size: 54px;
   line-height: 72px;
   margin-top: 24px;
   margin-bottom: 24px;
 }
 h2 {
   font-family: inherit;
   font-weight: 200;
   font-style: inherit;
   font-size: 36px;
   line-height: 48px;
   margin-top: 24px;
   margin-bottom: 24px;
 }
 h3 {
   font-family: inherit;
   font-weight: 600;
   font-style: inherit;
   font-size: 24px;
   line-height: 24px;
   margin-top: 24px;
   margin-bottom: 24px;
 }
 h4 {
   font-family: inherit;
   font-weight: 600;
   font-style: inherit;
   font-size: 16px;
   line-height: 24px;
   margin-top: 24px;
   margin-bottom: 24px;
 }
 h5 {
   font-family: inherit;
   font-weight: 700;
   font-style: inherit;
   font-size: 16px;
   line-height: 24px;
   margin-top: 24px;
   margin-bottom: 0px;
 }
 p, ul, ol, pre, table, blockquote {
   margin-top: 0px;
   margin-bottom: 24px;
 }
 ul ul, ol ol, ul ol, ol ul {
   margin-top: 0;
   margin-bottom: 0;
 }
 hr {
   border: 1px solid;
   margin: -1px 0;
 }
 b, strong, em, small, code {
   line-height: 1;
 }
 sup {
   line-height: 0;
   position: relative;
   vertical-align: baseline;
   top: -0.5em;
 }
 sub {
   bottom: -0.25em;
 }
 a {
   color: #268ED9;
 }
 .gridlover-grid {
   background-image: linear-gradient(to bottom, hsla(200, 100%, 50%, .3) 1px, transparent 1px);
   background-position: left -1px;
   background-repeat: repeat;
   background-size: 24px 24px;
 }
 .gridlover-grid body {
   box-shadow: 1px 0px 0px hsla(200, 100%, 50%, .3), -1px 0px 0px hsla(200, 100%, 50%, .3);
 }
